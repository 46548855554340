$primary-background: rgb(240 235 235);
$primary-background-contrast: rgb(255,255,255);
$primary-text: rgb(63, 60, 52);
$secondary-contrast: rgb(128, 149, 153);
$tertiary-contrast: rgb(161, 193, 204);

// Keeping these in for now, but this is generally the palette
// for my bio pic
// $test: rgba(128, 149, 153, 0.8);
// $test1: rgb(171, 182, 186);
// $test2: rgb(225, 217, 215);
// $test3: rgb(161, 193, 204);
// $test4: rgb(37, 114, 134);
// $test5: rgb(63, 60, 52);