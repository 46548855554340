@import './variables.scss';

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 2em;
  padding: 2em;
  border-radius: 8px;
  max-width: 75ch;
}
.pictureOfMe {
  flex: 1;
  margin: 0.5rem;
}
.bioContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
}

.title {
  flex: 2;
  font-size: 4rem;
  letter-spacing: 0.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: $secondary-contrast;
}
.bio {
  flex: 1;
  margin-bottom: 2rem;
  p {
    margin: 1rem 0rem;
  }
}
.small {
  font-size: 1rem;
  text-align: center;
  font-style: italic;
  margin: 0.5rem;
}
.backButton {
  padding: 0.5em;
  margin: 0.5em 0.25em;
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: linear-gradient(to right, $primary-background-contrast 50%, $secondary-contrast 50%) right bottom / 250% 100%;
  transition: all .8s ease-out;
  color: $primary-background-contrast;
}
.backButton:hover, .backButton:active {
  background-position: left bottom;
  color:  $primary-text; // inherit didn't work out here, possible because of <a> just before? 
}
.socialMediaLinks {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 2.5rem;

  a {
    color: rgb(37, 114, 134); // Might swap out for variable, a whole lotta blue in this area
    text-decoration: none;
  }
}
.socialMediaIntro {
  font-style: italic;
  font-size: 1rem;
  padding: 0.25rem;
  margin-bottom: 1rem;
  background-color: $secondary-contrast;
  border-bottom: 3px solid none;
  color: $primary-background-contrast;
}
.fontAwesomeIcons {
  color: $secondary-contrast;

}

@media screen and (max-width: 850px) {
  .aboutContainer {
    max-width: 36ch;
  }

  .title {
    font-size: 2rem;
  }

  .pictureOfMe {
    img {
      max-width: 325px;
    }
  }
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }
}