.homeContainer {
  display: flex;
  padding: 2rem;
  height: 100%;
}
.aboutPanel {
  display: flex;
  flex: 1;
  height: 100%;
  gap: 2rem;
  min-width: 404px;
}
.stickyContent {
  position: sticky;
  align-self: flex-start;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
}
.projectsPanel { 
  flex: 2;
}

@media screen and (max-width: 1200px) {
  .homeContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 1rem;
  }
  .stickyContent {
    position: static;
    top: auto;
    transform: none;
  }
}

@media screen and (max-width: 750px) {
  .aboutPanel {
    min-width: auto;
  }
}